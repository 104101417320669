<template>
  <div v-if="$auth.check() && is_mobile">
    <v-bottom-navigation :value="activeBtn" grow color="teal" app>
      <v-btn v-for="(link, i) in links.filter(l => l.active)" :key="i" :to="link.to">
        <!-- <span></span> -->
        <v-icon></v-icon>
      </v-btn>

      <!-- para links que precisam de route params (:id) -->
      <!-- <v-btn @click="pushToRoute()"> -->
      <!-- <span></span> -->
      <!-- <v-icon>mdi-account-star</v-icon>
      </v-btn>-->
    </v-bottom-navigation>
  </div>
</template>

<script>
import { bottom } from "@/router/links";
import resize from "@/mixins/resize";
export default {
  mixins: [resize],
  data() {
    return {
      activeBtn: 1,
      links: bottom
    };
  },
  methods: {
    pushToRoute() {
      // if (this.$route.name !== "RouteName")
      //   this.$router.replace({
      //     name: "RouteName",
      //     params: { paramId: ':variableId' }
      //   });
    }
  }
};
</script>
